import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'
import { CreateElement, RenderContext } from 'vue'
import fieldTypes from '.'

@Component({
  name: 'Field',
})
export default class Field extends Vue {
  @Prop({ type: String, required: true }) name!: string
  @Prop({ type: Object, required: true }) schema!: FormField
  @Prop({ type: Object, required: true }) environmentVariables!: Record<
    string,
    any
  >
  @Prop({ default: () => [] }) errorMessages!: string | string[]

  @Prop({ default: null }) value?: any
  @Prop({ type: Boolean, default: false }) viewOnly!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean

  render(h: CreateElement, ctx: RenderContext<Record<string, any>>) {
    // @ts-ignore
    const FormField = fieldTypes[this.schema.fieldType || this.schema.type]
    // Display error if there's no matching field
    if (!FormField) {
      return (
        <div class={{ 'error--text': true }}>
          {this.$t('Field_tsx.error')}(
          {this.schema.fieldType || this.schema.type})
        </div>
      )
    }
    // Render the actual field
    if (this.viewOnly) {
      return (
        <FormField.View
          key={this.name}
          name={this.name}
          schema={this.schema}
          value={this.value}
          errorMessages={this.errorMessages}
          disabled={this.disabled}
          environmentVariables={this.environmentVariables}
        />
      )
    } else {
      return (
        <FormField.Field
          key={this.name}
          name={this.name}
          schema={this.schema}
          value={this.value}
          errorMessages={this.errorMessages}
          disabled={this.disabled}
          onInput={(...args: any[]) => this.$emit('input', ...args)}
          environmentVariables={this.environmentVariables}
        />
      )
    }
  }
}
